<template>
  <Layout>
    <Header />
    <div class="container-fluid p-0">
      <div class="banner position-relative">
        <img src="@/assets/images/app/about.jpg" :alt="title" class="img-fluid">
        <div class="position-absolute top-50 start-0 translate-middle-y w-100">
          <div class="container text-white px-3">
              <div class="row justify-content-end">
              <div class="col-lg-8 col-10 text-end">
                <h1 class="text-white display-4 animate__animated animate__slideInRight font-style-2 mt-4 mt-md-0">{{$t('about_drlim')}}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid p-0">
      <div class="row align-items-center justify-content-center">
        <div class="col-12 px-0 col-lg-6 order-lg-first order-last">
          <img src="@/assets/images/app/about-2.jpg" alt="" class="img-fluid">
        </div>
        <div class="col-12 px-0 col-lg-6 text-center">
          <div class="row justify-content-center">
            <div class="col-10 col-lg-9">
              <div class="px-3 py-5 font-size-15 text-justify">
                <div v-html="$t('about_drlim_content')"></div>
              
                <div class="doc-profile-row__con">
                  <strong class="fw-medium text-primary d-block mb-3">{{$t('clinic_schedule')}}</strong>
                  <table class="table table-striped border font-size-14">
                    <tbody>
                  <tr>
                    <td>{{$t('monday')}}</td>
                    <td>1300 - 1700</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>{{$t('tuesday')}}</td>
                    <td>0900 - 1300</td>
                    <td>1400 - 1700</td>
                  </tr>
                  <tr>
                    <td>{{$t('wednesday')}}</td>
                    <td>0900 - 1300</td>
                    <td>1400 - 1700</td>
                  </tr>
                  <tr>
                    <td>{{$t('thursday')}}</td>
                    <td>0900 - 1300</td>
                  </tr>
                  <tr>
                    <td>{{$t('friday')}}</td>
                    <td>0900 - 1300</td>
                    <td>1400 - 1700</td>
                  </tr>
                  <tr>
                    <td>{{$t('saturday')}}</td>
                    <td>0900 - 1300</td>
                    <td></td>
                  </tr>
                  </tbody>
                  </table>
                  <a href="https://princecourt.com/appointment/?doctor_name=Lim+Hiong+Chin&doctor_category=General+Surgery" title="Schedule an Appointment" target="_blank" class="btn btn-primary"><i class="uil uil-calendar-alt me-2"></i> {{$t('schedule_an_appointment')}}</a>
									</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid p-0">
      <div class="row align-items-center justify-content-center">
        <div class="col-12 px-0 col-lg-6 text-center">
          <div class="row justify-content-center">
            <div class="col-10 col-lg-8">
              <div class="px-3 py-5 font-size-15 text-justify">
                <p>{{$t('about_drlim_content2')}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 px-0 col-lg-6">
          <img src="@/assets/images/app/about-1.jpg" alt="" class="img-fluid">
        </div>
      </div>
    </div>
    <Enquiry />
    <Contact />
  </Layout>
</template>
<script>
import Layout from "@/views/layouts/main";
import appConfig from "@/app.config";
import Header from "@/views/pages/drlim/components/header";
import Enquiry from '@/views/pages/drlim/components/enquiry';
import Contact from '@/views/pages/drlim/components/contact';
/**
 * Starter page
 */
export default {
  components: { Layout , Header, Enquiry, Contact},
  page: {
    title: "About Us",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  
  data() {
    return {
      title: appConfig.title,
      items: [],
     
    };
  },
  created(){

  },
  mounted(){
  },
  methods: {
  },
}
</script>